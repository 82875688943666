import reviewSlider from './import/review-slider.js'
import fixHeader from './helpers/fix-header'
import checkInput from './helpers/check-input'
import customFile from './helpers/custom-file';
import validateForm from './helpers/validate-form'
import scrollToAnchor from './helpers/scroll-to-anchor.js'
import toggleMenu from './helpers/toggle-menu.js';
import detectMob from './helpers/detect-mob.js';
import copyLink from './helpers/copy-link.js';
import cacheForm from './helpers/cache-form.js';

$(document).ready(function () {
  reviewSlider()
  accordion.init()
  fixHeader()
  checkInput($('.form__input, .form__textarea'))
  validateForm()
  toggleMenu.init()
  detectMob()
  scrollToAnchor();
  $('[type="tel"]').mask('+7 (000) 000-00-00');
  customFile();
  cacheForm()
  copyLink($('.sharing__copy'))
  $('.select_form').selectric({
    onChange: function () {
      $(this).closest('.selectric-wrapper').addClass('is-active')
      $(this).closest('.selectric-wrapper').removeClass('error')
      $(this).valid();
      if($(this).closest('form').valid()){
        $(this).closest('form').removeClass('has-errors')
      }
    }
  });
  $('.select_default').selectric({
    onChange: function() {
      let $val  = $(this).val();
      document.location.href= $val;
    }
  });

  $('.popup').on($.modal.BEFORE_OPEN, function (event, modal) {
    $('body').addClass('body-fixed modal-opened')
  });

  $('.popup').on($.modal.BEFORE_CLOSE, function (event, modal) {
    $('body').removeClass('body-fixed modal-opened');
    document.location.href="/";
  });


  $('[data-modal]').click(function (event) {
    $(this).modal({
      fadeDuration: 250
    });
    return false;
  });

})



const accordion = (function () {
  let $item = $('.accordion__head');
  const setup = function () {
    $item.click(function () {
      let hasClass = $(this).closest('.accordion__item').hasClass('is-collapsed');
      const that = this
      if (!hasClass) {
        openAccordion(that);
      } else {
        closeAccordion(that);
      }
    })
  }
  let openAccordion = (that) => {
    $(that).closest('.accordion__item').addClass('is-collapsed');
    $(that).siblings('.accordion__body').slideDown(300);
  }
  let closeAccordion = (that) => {
    $(that).closest('.accordion__item').removeClass('is-collapsed');
    $(that).siblings('.accordion__body').slideUp(300);
  }
  return {
    init: setup
  }

})();


