export default function (header = '.header') {
  $('[data-anchor]').click(function () {
    var $href = $(this).attr('href'),
        offset = $('.header').outerHeight() || 0;

    if ($(window).width() > 768) offset = 0;

    $('html, body').animate({
      scrollTop: $($href).offset().top - offset
    }, 800);

    return false;
  });
}
