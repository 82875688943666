var menuOpen = false,
  $body = $('body'),
  $menu = $('.mob-menu'),
  $menuIcon = $('.menu-icon');

function init() {
  $menuIcon.click(function () {
    if (!menuOpen) {
      openMenu();
    } else {
      closeMenu();
    }
    menuOpen = !menuOpen;
  });

  $('.mob-menu__link').click(function () {
    closeMenu();
    menuOpen = false;
  });
}

function openMenu() {
  $body.addClass('menu-opened body-fixed');
}

function closeMenu() {
  $body.removeClass('menu-opened body-fixed');
}

export default {
  init,
  openMenu,
  closeMenu
}
