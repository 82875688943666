export default function (btn) {
  btn.click(function (e) {
    e.preventDefault()
    let field = document.createElement('input');
    let text = window.location.href;

    document.body.appendChild(field);
    field.value = text;
    field.select();
    document.execCommand('copy');
    document.body.removeChild(field);
  })
}
