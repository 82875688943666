export default function () {
  var previousScroll = 0,
    $headerOffset = $('.header__row').height(),
    $head = $('.header');

  $(window).scroll(function () {
    var currentScroll = $(this).scrollTop();
    if (currentScroll > $headerOffset) {
      $head.addClass('header_white');
      if (currentScroll > previousScroll) {
        $head.addClass('header_hidden');
      } else {
        $head.removeClass('header_hidden');
      }
    } else {
      $head.removeClass('header_hidden header_white');
    }
    previousScroll = currentScroll;
  });
}
