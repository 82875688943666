export default function () {
  $('.form').each(function () {
    $(this).on('keyup change paste', 'input, select, textarea', function(){
      let $value = $(this).val();
      let $name = $(this).attr('name')
      let fields = ['name', 'phone', 'email'];

      if(fields.indexOf($name) != -1) {
        localStorage.setItem($name, $value)
      }

    });
  })

  $('.form input').each(function () {
   let $name = $(this).attr('name');
   if(localStorage.getItem($name) == null) {
     $(this).val('')
   } else {
     let $cacheValue = localStorage.getItem($name);
      $(this).val($cacheValue);
   }
  })
}
