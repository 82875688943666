export default function () {
  let time = 5;
  let $slick,
      isPause,
      tick,
      percentTime;

  $slick = $('.reviews__slider')
  let $bar

  $slick.on("init reInit ", function (event, slick, currentSlide, nextSlide){
    const i = (currentSlide ? currentSlide : 0);
    $bar = $(`[data-dot="${i}"] .progress-js__bar`)
    startProgressbar()
  })

  $slick.on("beforeChange", function (event, slick, currentSlide, nextSlide){
    resetProgressbar()
  })

  $slick.on("afterChange", function (event, slick, currentSlide, nextSlide){
    const i = (currentSlide ? currentSlide : 0);
    $bar = $(`[data-dot="${i}"] .progress-js__bar`)
    startProgressbar()
  })

  $slick.slick({
    arrows: false,
    dots: true,
    mobileFirst: true,
    pauseOnDotsHover: true,
    speed: 700,
    customPaging: function (slider, i) {
      return `<svg class="progress-js" data-dot="${i}" "viewBox="0 0 20 20"><g><circle class="progress-js__filled" cx="10" cy="10" r="7"/><circle class="progress-js__bar" cx="10" cy="10" r="7"/></g></svg>`;
    },
  });


  $slick.on({
    mouseenter: function () {
      isPause = true;
    },
    mouseleave: function () {
      isPause = false;
    }
  })

  function startProgressbar() {
    resetProgressbar();
    percentTime = 44;
    isPause = false;
    tick = setInterval(interval, 10);
  }

  function interval() {
    if (isPause === false) {
      percentTime -= 0.5 / (time + 0.1);
      $bar.css({
        strokeDashoffset: percentTime + "px"
      });
      if (percentTime <= 0) {
        $slick.slick('slickNext');
        resetProgressbar()
      }
    }
  }

  function resetProgressbar() {
    $('.progress-js__bar').css({
      strokeDashoffset: '44px'
    })
    clearTimeout(tick);
  }

  $('.reviews__btn_prev').click(function () {
    $slick.slick('slickPrev');
  })

  $('.reviews__btn_next').click(function () {
    $slick.slick('slickNext');
  })
}
