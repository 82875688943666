export default function () {
  var inputs = $('.file-box input');

  $(inputs).each(function () {
    var _self = $(this),
      _text = $(this).siblings('.file-box__text');

    _self.on('change', function (e) {
      var fileName = '';

      if (this.files) fileName = e.target.value.split('\\').pop();

      if (fileName) _text.text(fileName);
    });
  });
}
