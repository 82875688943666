export default function () {
  $('.form').each(function () {
    $(this).validate({
      errorElement: 'li',
      errorLabelContainer: '.form__warning',
      invalidHandler: function(form, validator) {
        var errors = validator.numberOfInvalids();
        if (errors) {
          $(form.currentTarget).addClass('has-errors')
        }
      },
      rules: {
        name: "required",
        email: {
          required: true,
          email: true
        },
        phone: {
          required: true,
          minlength: 18
        },
        city: 'required',
        projectName: 'required',
        projectDesc: {
          required: true,
          regx: /^[-.а-яА-ЯёЁ\s]+$/,
        },
        projectMessage: 'required',
        checkbox: 'required',
        companyName: 'required',
        direction: 'required'
      },
      highlight: function(element, errorClass, validClass) {
        var $element = $(element);

        if (element.type == 'select-one') {
          $element.closest('.selectric-wrapper').addClass(errorClass)
        } else {
          $element.addClass(errorClass)
        }



      },
      submitHandler: function(form) {
        $('.popup').modal({
          fadeDuration: 250
        });
      },
    });

      $.validator.addMethod("regx", function(value, element, regexpr) {
          return regexpr.test(value);
      });

      //change errormessage text
      $.validator.messages.required = function (value, element) {
        return $(element).attr('placeholder') + '<span>,</span>'
      }
      $.validator.messages.regx = function (value, element) {
        return $(element).attr('placeholder') + '<span>,</span>'
      }
      $.validator.messages.email = function (value, element) {
        return $(element).attr('placeholder') + '<span>,</span>'
      }
      $.validator.messages.minlength = function (value, element) {
        return $(element).attr('placeholder') + '<span>,</span>'
      }


      $(this).on('keyup change paste', 'input, select, textarea', function(){
        if($(this).closest('form').valid()){
          $(this).closest('form').removeClass('has-errors')
        } else {
          $(this).closest('form').addClass('has-errors')
        }
      });

    })
}
